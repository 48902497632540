/* src/pages/Home.css */

.home-container {
    padding: 2rem;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .home-text {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
  }
  
  .app-download {
    margin-top: 2rem;
  }
  
  .app-download img {
    max-width: 100%;
    height: auto;
  }
  
  /* Responsive Styles */
  @media (min-width: 600px) {
    .home-container {
      padding: 3rem;
    }
  
    .home-header {
      font-size: 2.5rem;
    }
  
    .home-text {
      font-size: 1.2rem;
    }
  }
  
  @media (min-width: 900px) {
    .home-container {
      padding: 4rem;
    }
  
    .home-header {
      font-size: 3rem;
    }
  
    .home-text {
      font-size: 1.5rem;
    }
  }
  