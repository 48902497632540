/* src/pages/Contact.css */

.contact-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  
  .contact-form label {
    margin: 0.5rem 0 0.2rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    width: 150px;
    padding: 0.5rem;
    border: none;
    background-color: #007aff;
    color: white;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .status-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  
  .faq-section {
    border-top: 1px solid #eee;
    padding-top: 1rem;
  }
  
  .faq-item {
    margin-bottom: 1.5rem;
  }
  
  .faq-item h4 {
    margin-bottom: 0.5rem;
  }
  