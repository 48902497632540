/* src/components/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #004466;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}

.navbar-logo {
  color: #ffffff;
  font-size: 1.5rem;
  text-decoration: none;
}

.menu-icon {
  display: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  background: none;
  border: none;
}

.menu-icon:focus {
  outline: 2px solid #ffcc00;
  outline-offset: 2px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links li a:hover,
.nav-links li a.active-link {
  color: #ffcc00; /* Highlight color for active and hovered links */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 70px; /* Adjust based on navbar height */
    right: 0;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #004466;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto; /* Enable scrolling if content is long */
  }

  .nav-links.mobile {
    transform: translateX(0);
  }

  .nav-links li {
    margin: 1.5rem 0;
  }
}
